@import "../../assets/styles/breakpoints";

a {
  text-decoration: none;
}
.landing {
  background-color: #f9fafb;

  &__secondSection {
    display: grid;
    padding-top: 50px;
    padding-bottom: 50px;
    &-subtitle {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #000;
      margin-bottom: 40px;
    }
    &-wrapper {
      display: grid;
      width: 75vw;
      grid-template-columns: 44% 44%;
      justify-self: center;
      grid-gap: 12%;
      @include media("<=phone") {
        display: block;
        margin: auto;
        width:90%;
      }
    }
    @include media("<=phone") {
      display: block;
    }
    &-links {
      color: white;
      height: clamp(374px, 650px, 674px);
      width: clamp(362px, 30vw, 662px);
      @include media("<=phone") {
        height: 100%;
        width: 100%;
        margin-bottom: 15px;
      }
      display: grid;
      align-content: flex-end;
      border-radius: 48px;
      @media (max-width: 1750px) {
        height: 600px;
      }
      @media (max-width: 1550px) {
        height: 550px;
      }
      @media (max-width: 1400px) {
        height: 500px;
      }
      &.be-a-nomad {
        justify-self: flex-end;
        @include media("<=phone") {
          justify-self: center;
        }
      }
      &.be-a-local {
        justify-self: flex-start;
        @include media("<=phone") {
          justify-self: center;
        }
      }

      &-image {
        position: relative;
        bottom: 0px;
        height: auto;
        width: auto;
      }
      &-wrapper {
        display: grid;
        justify-items: center;
        .ant-image {
          display: grid;
          justify-items: center;
        }
        p {
          color: #000;
          margin-top: 30px;
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 8px;
        }
        &Arrow {
          height: 16px;
          margin-left: 10px;
          padding-top: 10px;
        }
        svg {
          padding-top: 8px;
        }
      }
    }
  }
}
