.box-testimonial {
  width: 90%;
  position: absolute;
  bottom: 24px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* Background blur / lg */
  
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 8px;
}

.secondButton {
  border: 1px solid #ccc !important;
  color: black !important;
  padding: 10px 25px !important;
}

.slick-list {
  padding-left: 0px!important; 
}
