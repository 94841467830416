.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #000 !important;
  border-color: transparent;
  color: #fff;
  border-radius: 40px;
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  height: 31px !important;
}

.daterangepicker {
  padding: 15px !important;
}
.daterangepicker .drp-buttons .btn{
  background-color: #FD5854 !important;
  border-color: transparent;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
}