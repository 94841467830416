.people-here-now {
  text-align: center;
}

.people-here-now h3 {
  margin: 2rem;
}
.avatar {
  background-color: var(--input-hover-color);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 1px solid var(--input-border-color);
  border: none;
  box-shadow: var(--box-shadow-central);
  background-size: cover;
  object-fit: cover;
  vertical-align: middle;
  display: inline-block;
  margin: 7px;
  cursor: pointer;
}
