.searchresults__map{
  position: fixed;
  width: 100%;
  align-items: center;
  height: 88.5vh;
}

.officePopup {
  display: grid;
  grid-template-columns: 100px auto;
}
.officePopup .popupContent {
  padding: 10px;
  min-width: 200px;
  font-size: 16px;
  font-weight: 900;
  color: #523f7b;
}
.officePopup .popupContent .workplaceType {
  font-size: 14px;
  font-weight: 600;
}
.officePopup .popupContent .popupFooter {
  margin-top: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 35% 65%;
}
.officePopup .popupImage {
  border-radius: 24px 0px 0px 24px;
  height: 90px;
  background-size: cover !important;
}

.priceMarker {
  display: grid;
  grid-template-columns: 28px auto;
  min-width: 80px;
  height: 27px !important;
  background: white !important;
  border-radius: 30px !important;
  border: 0 !important;
  align-items: center;
  font-weight: 900;
  font-size: 16px;
  justify-items: center;
}
.priceMarker .meetingroom {
  color: white;
  background: #e52270;
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  display: grid;
  align-items: center;
  justify-items: center;
}
.priceMarker .office {
  color: white;
  background: #fda84d;
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  display: grid;
  align-items: center;
  justify-items: center;
}
.priceMarker .desk {
  color: white;
  background: #fd6a56;
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  display: grid;
  align-items: center;
  justify-items: center;
}
.priceMarker .misc {
  color: white;
  background: #523f7b;
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  display: grid;
  align-items: center;
  justify-items: center;
}
