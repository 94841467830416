.map-visible {
  position: relative;
  height: 100%;
  width: 100%;
}
.map-visible .mapboxgl-map {
  height: 100%;
  min-width: 600px;
  width: 100%;
  z-index: 1;
}

.officeMarker {
  border: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  background-size: contain !important;
  background: url("../../../../public/twimyo-pin.png") no-repeat;
}

/*Create Workspace*/
.map-visible-create {
  position: relative;
  height: 100%;
  width: 100%;
}
.map-visible-create .mapboxgl-map {
  height: 100%;
  max-width: 600px;
  width: 100%;
  z-index: 1;
}

.officeMarker {
  border: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  background-size: contain !important;
  background: url("../../../../public/twimyo-pin.png") no-repeat;
}

.geocoder {
  border-radius: 30px !important;
  background: white !important;
  width: 100%;
}

.geocoder .mapboxgl-ctrl-geocoder--pin-right {
  display: none;
}
.geocoder svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}
.geocoder input {
  height: 55px;
  width: 100%;
  border: none;
  border-radius: 15px;
  border: 1px solid #e6e6e6;
  padding: 15px;
  font-size: 14px;
}
.geocoder input::placeholder {
  color: #523f7b60;
}
.geocoder .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  border: 0 !important;
}

.geocoder {
	min-height: 40px !important;
	border-radius: 30px !important;
	border-color: #a2aab7 !important;
	width: 100%;
	svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
		display: none;
	}
	input {
		padding: 15px;
		font-size: 14px;
	}
	input::placeholder {
		color: #bfbfbf;
	}
}

.mapboxgl-ctrl-geocoder--input:focus {
	outline: none !important;
}

.mapboxgl-ctrl {
	.suggestions:after {
		content: '';
		width: 93%;
		height: 87%;
		position: absolute;
		z-index: 0;
		left: 22px;
		top: 18px;
		filter: blur(10px);
		transform: translateZ(0);
	}

	.suggestions {
		padding: 10px !important;
		background: transparent !important;
		box-shadow: none !important;
		position: relative;
		list-style: none;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 300px;
		li {
			z-index: 1;
			position: relative;
			cursor: pointer;
			background: #f2f2f2;
			margin: 5px;
			border-radius: 10px;
			padding: 10px 10px;
			a {
				color: #523f7b;
			}
			&.active {
				a {
					background: #efefef !important;
					color: #fd6a56;
					border-radius: 30px;
				}
			}
		}
	}
}

.officeInputs {
	padding: 30px;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
}
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
	width: 100%;
	max-width: 100%;
	min-height: 40px;
	border-radius: 30px !important;
	border: 1px solid #a2aab7;
	box-shadow: none;
}
.geocoder input {
	display: flex;
	justify-self: center;
	align-self: center;
}

.mapboxgl-ctrl-geocoder--powered-by{
	display: none !important;
}

.officeMarker {
  border: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  background-size: contain !important;
  background: url('../../../../public/twimyo-pin.png') no-repeat;
}

.officePopup {
	.popupContent {
		padding: 10px;
		font-size: 16px;
		font-weight: 900;
		color: #523f7b;
		margin-bottom: 0;
		margin-top: 12px;
	}
}
.mapboxgl-popup-close-button,
.mapboxgl-popup-tip {
	display: none;
}
.mapboxgl-popup-anchor-bottom {
	max-width: 100% !important;
}
.mapboxgl-popup-content {
	position: relative;
	background: #fff;
	border-radius: 8px;
	pointer-events: auto;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	max-width: 250px;
	top: -30px;
}
