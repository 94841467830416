a.socialNetworks {
    text-decoration: none;
    color: #000;
    font-size: 11px;
    margin: 0 5px;
}

/* .linksFooter{
  flex-flow: column nowrap;
} */

a.linksFooter {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
 a.linksFooter:hover{
  text-decoration : underline;
 }

